<template>
  <div class="container is-max-desktop mb-4">
    <!-- Profile general -->
    <div class="box">
      <div class="columns mb-2">
        <div class="column is-3">
          <figure class="image is-128x128" style="margin: 0 auto">
            <img class="img-profile" :src="getEmployer.logo" />
          </figure>
        </div>
        <div class="column">
          <div
            class="flex"
            style="display: flex; justify-content: space-between"
          >
            <h1
              class="cover-title mb-1"
              style="font-size: 1.5rem; font-weight: 600"
            >
              {{ getEmployer.name }}
            </h1>
            <profile-edit></profile-edit>
          </div>

          <EmployerLink
            :url="getEmployer.get_absolute_url"
            :slug="getEmployer.slug"
          />
          <div class="columns mt-2">
            <div class="column is-4">
              <b-progress
                :type="barClass"
                :value="profilePercent"
                show-value
                format="percent"
              ></b-progress>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CurrentSubscription
      v-if="getEmployer.current_subscription"
      :getEmployer="getEmployer"
    />
    <EmployerForm />
  </div>
</template>

<script>
import CurrentSubscription from "../../components/ui/CurrentSubscription.vue";
import EmployerForm from "../../components/forms/EmployerForm.vue";
import ProfileEdit from "../../components/small/ProfileEditBtn.vue";
import EmployerLink from "../../components/small/EmployerPageLink.vue";

export default {
  components: {
    EmployerForm,
    ProfileEdit,
    CurrentSubscription,
    EmployerLink,
  },
  computed: {
    getEmployer() {
      return this.$store.getters["employers/getEmployer"];
    },
    profilePercent() {
      return this.$store.getters["employers/getEmployerPercentage"];
    },
    barClass() {
      if (this.profilePercent == 100) {
        return "is-primary";
      } else {
        return "is-warning";
      }
    },
  },
  methods: {
    //
  },
};
</script>

<style scoped>
.no-shadow {
  box-shadow: none !important;
}

.side-bar {
  position: relative;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 5rem;
}

.red {
  border: 1px solid red;
}

.blue {
  border: 1px solid blue;
}

.bold {
  font-weight: 600;
}
</style>
