<template>
  <div class="box is-secondary">
    <div class="heading mb-4">Current Subscription Plan</div>
    <div class="columns mb-2">
      <div class="column">
        <div class="field">
          <label class="label">Plan</label>
          <b-input
            :value="getEmployer.current_subscription.plan.name"
            readonly
          ></b-input>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Start date</label>
          <b-input
            :value="dateString(getEmployer.current_subscription.start_date)"
            readonly
          ></b-input>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">End date</label>
          <b-input
            :value="dateString(getEmployer.current_subscription.end_date)"
            readonly
          ></b-input>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Status</label>
          <b-input
            :value="getStatus(getEmployer.current_subscription.end_date)"
            readonly
          ></b-input>
        </div>
      </div>
    </div>
    <upgrade-btn></upgrade-btn>
  </div>
</template>

<script>
import UpgradeBtn from "../../components/small/UpgradeBtn.vue";

export default {
  components: {
    UpgradeBtn,
  },
  props: {
    getEmployer: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getStatus(endDate) {
      // if end date is before today, then expired
      if (endDate < new Date()) {
        return "Expired";
      } else {
        return "Active";
      }
    },
    dateString(date) {
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      // return date object first
      var d = new Date(date);
      // convert date in format of "10 Jan 2022"
      return d.getDate() + " " + months[d.getMonth()] + " " + d.getFullYear();
    },
  },
};
</script>

<style></style>
