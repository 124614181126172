<template>
  <!-- Tags -->
  <div>
    <div class="box" style="min-height: 25vh">
      <div class="heading mb-4">General</div>
      <div class="columns mb-4">
        <div class="column">
          <div class="field">
            <label class="label">Introduction</label>
            <div class="control">
              <p v-if="getEmployer.intro">{{ getEmployer.intro }}</p>
              <p v-else></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box" style="min-height: 25vh">
      <div class="heading mb-4">Contact Details</div>
      <div class="columns">
        <div class="column is-3">
          <div class="field">
            <label class="label">Phone</label>
            <div class="control">
              <p>{{ getEmployer.phone }}</p>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <p>{{ getEmployer.email }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Address information -->
    <div class="box" style="min-height: 25vh">
      <div class="heading mt-4 mb-4">Address</div>
      <div class="columns">
        <div class="column is-3">
          <div class="field">
            <label class="label">Location</label>
            <div class="control">
              <p v-if="this.getEmployer.location">
                {{ getEmployer.location.name }}
              </p>
              <p v-else></p>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="field">
            <label class="label">Address</label>
            <div class="control">
              <p v-if="getEmployer.address">{{ getEmployer.address }}</p>
              <p v-else></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Helper information -->
    <div class="box" style="min-height: 25vh">
      <div class="heading mt-4 mb-4">Helper Information</div>
      <div class="columns mb-4">
        <div class="column is-3">
          <div class="field">
            <label class="label">Organization Size</label>
            <div class="control">
              <p v-if="this.getEmployer.org_size">
                {{ getEmployer.org_size.name }}
              </p>
              <p v-else></p>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="field">
            <label class="label">Sector</label>
            <div class="control">
              <p v-if="this.getEmployer.sector">
                {{ getEmployer.sector.name }}
              </p>
              <p v-else></p>
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="field">
            <label class="label">Website</label>
            <div class="control">
              <p v-if="getEmployer.website">{{ getEmployer.website }}</p>
              <p v-else></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      readOnly: true,
    };
  },
  computed: {
    getEmployer() {
      return this.$store.getters["employers/getEmployer"];
    },
  },
};
</script>

<style></style>
