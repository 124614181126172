import { render, staticRenderFns } from "./TheProfile.vue?vue&type=template&id=5bb6bafd&scoped=true&"
import script from "./TheProfile.vue?vue&type=script&lang=js&"
export * from "./TheProfile.vue?vue&type=script&lang=js&"
import style0 from "./TheProfile.vue?vue&type=style&index=0&id=5bb6bafd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bb6bafd",
  null
  
)

export default component.exports